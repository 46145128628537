<template>
  <div class="context_whole" style="position: fixed;width: 100%;height: 100%">
    <van-nav-bar
        style="background-color:rgb(48, 65, 86);height: 6%;"
        title="视频回放"

        @click-left="onClickLeft"
        @click-right="onClickRight"
    />
    <div v-for="(item,index) in list" :key="index" :url="item.url" style="margin-top:10px;">

      <video :src="item.url" style="width: 85%;margin-left:8%;height:10%;"></video>
      <span style="margin-left: 12%;">{{item.name}}</span>
      <!-- </div> -->
    </div>
    <div style="width: 100%;height:14%;margin-right:5px;position: fixed">
      <van-pagination
          v-model="currentPage"
          :page-count="totalPage"
          items-per-page="10"
          mode="simple"
          @change="changePage"
      />
    </div>
    <van-tabbar v-model="active" class="bottom"  inactive-color="black" active-color="#1988f9">
      <van-tabbar-item  name="meeting" icon="wap-home-o" to="/meeting">视频会议</van-tabbar-item>
      <van-tabbar-item name="video" icon="video" to="/meeting">视频回放</van-tabbar-item>
      <van-tabbar-item name="photo" icon="photo" to="/photo">图片浏览</van-tabbar-item>
      <van-tabbar-item  name="user" icon="user-o" to="/user">个人中心</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>

export default {
  name: "video",
  components:{
  },
  data() {
    return {
      active: 'video',
      currentPage: 1,
      pageSize:3,
      totalPage:10,
      list:[
      ]
    }
  },
  async created() {
    let acc0 = this.$route.query.AccountName || this.$route.query.accountname;//获取当前页面路径
    const base_url = 'https://root.xuxiao888.com'
    //const base_url = 'http://localhost:9090'
    // const res = await this.$axios.get(`https://223.83.132.54:9090/file/page/mp4/all?pageNum=${this.currentPage}&pageSize=${this.pageSize}`)
    const res = await this.$axios.get(`${base_url}/file/page/mp4/all?pageNum=${this.currentPage}&&pageSize=${this.pageSize}`)
    if (res.data.code == '200') {
      let data=res.data.data;
      this.list=data.records;
      this.totalPage=data.pages;
    }
  },
  methods:{
    async changePage(){
      const base_url = 'https://root.xuxiao888.com'
      //const base_url = 'http://localhost:9090'
      const res = await this.$axios.get(`${base_url}/file/page/mp4/all?pageNum=${this.currentPage}&&pageSize=${this.pageSize}`)
      if (res.data.code == '200') {
        let data=res.data.data;
        this.list=data.records;
      }
    }
  },
}
</script>

<style scoped>

</style>